exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-content-pages-boka-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/page-query.tsx?__contentFilePath=/Users/jakob/Documents/bizart/frontend/content/pages/boka/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-content-pages-boka-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-content-pages-coworking-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/page-query.tsx?__contentFilePath=/Users/jakob/Documents/bizart/frontend/content/pages/coworking/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-content-pages-coworking-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-content-pages-event-gallery-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/page-query.tsx?__contentFilePath=/Users/jakob/Documents/bizart/frontend/content/pages/event-gallery/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-content-pages-event-gallery-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-content-pages-hitta-hit-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/page-query.tsx?__contentFilePath=/Users/jakob/Documents/bizart/frontend/content/pages/hitta-hit/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-content-pages-hitta-hit-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-content-pages-kontakta-oss-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/page-query.tsx?__contentFilePath=/Users/jakob/Documents/bizart/frontend/content/pages/kontakta-oss/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-content-pages-kontakta-oss-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-content-pages-om-oss-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/page-query.tsx?__contentFilePath=/Users/jakob/Documents/bizart/frontend/content/pages/om-oss/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-page-query-tsx-content-file-path-content-pages-om-oss-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-projects-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-emma-core/src/templates/projects-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-emma-core-src-templates-projects-query-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

